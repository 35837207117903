const Autodesk = window.Autodesk;

export default class BIMAcademyUtilsGeometryQuery extends Autodesk.Viewing
    .Extension {
    viewer;
    options;
    subToolbar;

    constructor(viewer, options) {
        super(viewer, options);
        this.viewer = viewer;
        this.options = options;
    }

    load = function() {
        return true;
    };

    unload = function() {
        this.viewer.toolbar.removeControl(this.subToolbar);
        return true;
    };

    onToolbarCreated() {
        // Create a new toolbar group if it doesn't exist
        this._group = this.viewer.toolbar.getControl(
            "allMyAwesomeExtensionsToolbar"
        );
        if (!this._group) {
            this._group = new Autodesk.Viewing.UI.ControlGroup(
                "allMyAwesomeExtensionsToolbar"
            );
            this.viewer.toolbar.addControl(this._group);
        }

        // Add a new button to the toolbar group
        this._button = new Autodesk.Viewing.UI.Button("Find dbId of selected");
        this._button.onClick = () => {
            // Get current selection
            const selection = this.viewer.getSelection();
            this.viewer.clearSelection();
            // Anything selected?
            if (selection.length > 0) {
                const isolated = [];
                // Iterate through the list of selected dbIds
                selection.forEach(dbId => {
                    // Get properties of each dbId
                    this.viewer.getProperties(dbId, props => {
                        // Output properties to console

                        // Ask if want to isolate
                        if (
                            confirm(
                                `Isolate ${props.name} (${props.externalId}) [dbId: ${props.dbId}]?`
                            )
                        ) {
                            isolated.push(dbId);
                            this.viewer.isolate(isolated);
                        }
                    });
                });
            } else {
                // If nothing selected, restore
                this.viewer.isolate(0);
            }
        };
        this._button.setToolTip("Handle Selection Extension");
        this._button.addClass("handleSelectionExtensionIcon");
        this._group.addControl(this._button);
    }
}
Autodesk.Viewing.theExtensionManager.registerExtension(
    "BIMAcademy.Utils.GeometryQuery",
    BIMAcademyUtilsGeometryQuery
);
